import { graphql, Link } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import BreadCrumb from '../../components/bread-crumb/bread-crumb';
import ContainerBox from '../../components/container-box/container-box';
import ExpandShare from '../../components/expand-share/expand-share';
import PageNavigationButton from '../../components/page-navigation-button/page-navigation-button';
import { PageNavigationDirection } from '../../components/page-navigation-button/page-navigation-button-enum';
import SocialMetaData from '../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../components/social-metadata/social-metadata-enum';
import SvgIcon from '../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../components/svg-icon/svg-icon-enum';
import WordPressPageLayout from '../../components/wordpress-page-layout/wordpress-page-layout';
import { IReadTheReportTemplateProperties } from './read-the-report-interface';
import ReadTheReportTableOfContent from './table-of-content/table-of-content';

export default class ReadTheReportTemplate extends BaseComponent<IReadTheReportTemplateProperties> {
  public render(): ReactNode {
    const { tableOfContent, nextPage, previousPage } = this.props.pageContext;
    const { reportPage } = this.props.data;
    return (
      <WordPressPageLayout title={reportPage.title}>
        <SocialMetaData
          type={SocialMetadataType.SUMMARY}
          title={reportPage.title}
          description={reportPage.title}
          imageResourceFileName={''}
        />
        <ContainerBox className="c-section--pageheader">
          <div className="c-pageheader">
            <BreadCrumb
              crumbItems={[
                {
                  label: 'State of Democracy',
                  location: '/',
                },
                {
                  label: 'Read the report',
                  location: '/read-the-report',
                },
              ]}
            />
            <div className="c-section">
              <h1 className="c-section__title">Read the report</h1>
            </div>
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--report">
          <div className="row c-read-report">
            <div className="col-lg-4">
              <div className="c-read-report__sidebar">
                <div className="c-read-report__share">
                  <div className="c-read-report__download">
                    <Link className="c-download" download to="#">
                      <SvgIcon
                        width="24"
                        viewBox="0 0 24 24"
                        paths={[
                          {
                            command: SvgIconPathCommand.DOWNLOAD_REPORT,
                            fill: '#4A1D96',
                          },
                        ]}
                      />
                      Download report
                    </Link>
                  </div>
                  <ExpandShare absoluteLink="/read-the-report" />
                </div>
                <ReadTheReportTableOfContent tableOfContent={tableOfContent} />
              </div>
            </div>
            <div className="col-lg-8">
              <div
                dangerouslySetInnerHTML={{ __html: reportPage.content }}
                className="s-content"
              ></div>
              <div className="row c-pagination">
                <div className="col-md-6 c-pagination__col">
                  {previousPage && (
                    <PageNavigationButton
                      direction={PageNavigationDirection.PREVIOUS}
                      label="Previous"
                      pageTitle={previousPage.title}
                      url={previousPage.uri}
                    />
                  )}
                </div>
                <div className="col-md-6 c-pagination__col">
                  {nextPage && (
                    <PageNavigationButton
                      direction={PageNavigationDirection.NEXT}
                      label="Next"
                      pageTitle={nextPage.title}
                      url={nextPage.uri}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ContainerBox>
      </WordPressPageLayout>
    );
  }
}

export const query = graphql`
  query ReadTheReportPageById($pageId: String!) {
    reportPage: wpPage(id: { eq: $pageId }) {
      id
      content
      title
      slug
    }
  }
`;
