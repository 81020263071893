import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import SolidLink from '../solid-link/solid-link';
import { SolidLinkColorSchemas } from '../solid-link/solid-link-enum';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import { PageNavigationDirection } from './page-navigation-button-enum';
import { IPageNavigationButtonProperties } from './page-navigation-button-interface';

export default class PageNavigationButton extends BaseComponent<IPageNavigationButtonProperties> {
  public render(): ReactNode {
    const { direction, label, pageTitle, url } = this.props;

    return (
      <div className='c-pagination__items'>
        <span>
          <div className='c-pagination__title'>{label}</div>
          <div className='c-pagination__pagetitle'>{pageTitle}</div>
        </span>
        <SolidLink colorSchema={SolidLinkColorSchemas.HIGHLIGHTED} to={url}>
          {direction === PageNavigationDirection.NEXT ? 
            <SvgIcon
              width="20"
              viewBox="0 0 20 20"
              paths={[
                { command: SvgIconPathCommand.NEXT_ARROW, fill: "#4A1D96" },
              ]}
            /> : 
            <SvgIcon
              width="20"
              viewBox="0 0 20 20"
              paths={[
                { command: SvgIconPathCommand.PREV_ARROW, fill: "#4A1D96" },
              ]}
            />
            }
        </SolidLink>
      </div>
    );
  }
}
