import React, { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import BaseComponent from '../../../base/base-component';
import SvgIcon from '../../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../../components/svg-icon/svg-icon-enum';
import ReadTheReportTableOfContentItem from '../table-of-content-item/table-of-content-item';
import {
  IReadTheReportTableOfContentProperties,
  IReadTheReportTableOfContentState,
} from './table-of-content-interface';
import './table-of-content.scss';

export default class ReadTheReportTableOfContent extends BaseComponent<
  IReadTheReportTableOfContentProperties,
  IReadTheReportTableOfContentState
> {
  public constructor(props: IReadTheReportTableOfContentProperties) {
    super(props);

    this.state = {
      activeAccordionItemKey: '0',
    };
  }

  public render(): ReactNode {
    const { tableOfContent } = this.props;
    const isBrowser = typeof window !== 'undefined';
    let activeKey = '0';

    const tableView = tableOfContent.map((content, index) => {
      const urlMatched =
        isBrowser &&
        (window.location.pathname === content.uri ||
          `${window.location.pathname}/` === content.uri);

      if (urlMatched) {
        activeKey = index.toString();
      }

      return (
        <Accordion.Item eventKey={index.toString()} key={index}>
          {content.segments && content.segments.length > 0 ? (
            <>
              <Accordion.Header className={urlMatched ? 'is-active' : ''}>
                {content.title}
                <SvgIcon
                  width="18"
                  viewBox="0 0 18 18"
                  paths={[
                    {
                      command: SvgIconPathCommand.ARROW_DOWN,
                      fill: '#0D0C0D',
                    },
                  ]}
                />
              </Accordion.Header>
              <Accordion.Body>
                {content.segments &&
                  content.segments.map((segment, segmentIndex) => {
                    return (
                      <ReadTheReportTableOfContentItem
                        title={segment.title}
                        url={`/read-the-report/${content.slug}#${segment.slug}`}
                        key={segmentIndex}
                        slug={segment.slug}
                      />
                    );
                  })}
              </Accordion.Body>
            </>
          ) : (
            <div className="accordion-item">
              <h2
                className={
                  urlMatched
                    ? 'accordion-header is-singular is-active'
                    : 'accordion-header is-singular'
                }
              >
                <ReadTheReportTableOfContentItem
                  title={content.title}
                  url={content.uri}
                  slug={content.slug}
                />
              </h2>
            </div>
          )}
        </Accordion.Item>
      );
    });

    return (
      <div className="c-table-content">
        <div className="c-table-content__title">Contents</div>
        <Accordion defaultActiveKey={activeKey}>{tableView}</Accordion>
      </div>
    );
  }
}
