import React, { ReactNode } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import BaseComponent from '../../base/base-component';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import {
  IExpandShareProperties,
  IExpandShareState,
} from './expand-share-interface';
import './expand-share.scss';

export default class ExpandShare extends BaseComponent<
  IExpandShareProperties,
  IExpandShareState
> {
  public constructor(properties: IExpandShareProperties) {
    super(properties);

    this.state = {
      copyLinkText: this.translate('copy'),
    };
  }

  protected declareTranslateCollection(): string | undefined {
    return 'share';
  }

  private onCopyClick(): void {
    const { absoluteLink } = this.props;
    const copyLink = `${window.location.protocol}//${window.location.host}${absoluteLink}`;
    navigator.clipboard.writeText(copyLink).then((): void => {
      this.setState({
        copyLinkText: this.translate('copied'),
      });
    });
  }

  protected getResourcesPath(): string {
    return '';
  }

  public render(): ReactNode {
    const { absoluteLink, description } = this.props;

    let publicLink = '';

    //check if window defined.(prevent issue on SSR)
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      publicLink = `${window.location.protocol}//${window.location.host}${absoluteLink}`;
    }

    return (
      <div className="c-expand-share">
        <div className="c-expand-share__box">
          <FacebookShareButton url={publicLink} quote={description}>
            <SvgIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              paths={[
                { command: SvgIconPathCommand.FACEBOOK, fill: '#1877F2' },
              ]}
            />
            <span>Facebook</span>
          </FacebookShareButton>
          <TwitterShareButton url={publicLink}>
            <SvgIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              paths={[{ command: SvgIconPathCommand.TWITTER, fill: '#1DA1F2' }]}
            />
            <span>Twitter</span>
          </TwitterShareButton>
          <WhatsappShareButton url={publicLink}>
            <SvgIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              paths={[
                {
                  command: SvgIconPathCommand.WHATSAPP,
                  fill: '#25D366',
                  fillRule: 'evenodd',
                  clipRule: 'evenodd',
                },
              ]}
            />
            <span>WhatsApp</span>
          </WhatsappShareButton>
          <button
            className="react-share__ShareButton is-copy"
            onClick={(): void => {
              this.onCopyClick();
            }}
          >
            <SvgIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              paths={[
                {
                  command: SvgIconPathCommand.COPY,
                  fillRule: 'evenodd',
                  clipRule: 'evenodd',
                  fill: '#000',
                },
              ]}
            />
            <span>{this.state.copyLinkText}</span>
          </button>
        </div>
      </div>
    );
  }
}
